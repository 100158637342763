<template>
  <div class="admin-view">
    <control-panel>
      <template v-slot:side-menu>
        <div class="side-menu">
        </div>
      </template>

      <template v-slot:content>
        <div 
          class="content"
          v-if="sideMenuActiveItem.name === 'my_specialists'"
        >
          <div class="content__header">
            <div class="content__header__title">
              {{$localize.get('vacancies')}}
            </div>
            <h-button
              @click.native="$store.dispatch('OPEN_VACANCY_EDITOR', {type: 'new'})"
              class="button--join"
              filled blue x-small-padding
            >
              {{$localize.get('add_vacancy')}}
              <template v-slot:icon-right>
                <v-icon color="#fff">mdi-plus</v-icon>
              </template>
            </h-button>
          </div>

          <div class="content__main">
            <data-table 
              :filterData="tables.vacancies.filterData"
              :setFilterData="setFilterData"
              :headerData="profileSpecialistsTableHeaderData"
              :tableData="adminVacanciesTableData"
              :itemStyles="{
                gridTemplateColumns: '64px 2fr 1fr 1fr 1fr 1fr'
              }"
            ></data-table>
          </div>
        </div>
      </template>
    </control-panel>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'Admin',
  data() {
    return {
      tables: {
        vacancies: {
          filterData: []
        }  
      }
    }
  },
  mounted () {
    this.fetchTablesSpecialistsFilterData()
  },
  watch: {
    selectedLanguage () {
      this.fetchTablesSpecialistsFilterData()
    }
  },
  computed: {
    ...mapGetters([
      'adminTopSideMenuItemsLocalized',
      'adminBottomSideMenuItemsLocalized',
    ]),
    admin () {
      return this.$store.state.admin
    },
    selectedLanguage () {
      return this.$store.state.storageData.settings.localization.selectedLanguage
    },
    sideMenuActiveItem: {
      get () {
        return this.$store.state.views.admin.sideMenu.activeItem
      },
      set (value) {
        this.$store.state.views.admin.sideMenu.activeItem = value
      }
    },
    adminVacanciesTableDataCustomers () {
      return [...new Set(this.adminVacanciesTableData.map(item => item.properties.customer))]
    },
    adminVacanciesTableDataStatuses () {
      return [...new Set(this.adminVacanciesTableData.map(item => item.properties.status.text))]
    },
    adminVacanciesTableData () {
      let vacancies = JSON.parse(JSON.stringify(this.admin.vacancies))
      let data = []
      vacancies.forEach((item) => {
        let dataObject = {
          onClick: () => {
            this.$store.dispatch('OPEN_VACANCY_EDITOR', {type: 'edit'})
          },
          properties: {
            id: item.id,
            role: {
              name: item.name,
              role: item.role,
            },
            date: item.date,
            customer: item.customer,
            status: item.status,
            actions: {
              ...item.actions,
              duplicate: {
                actionClass: 'duplicate',
                iconClass: 'duplicate-icon',
                icon: require('../assets/icon-duplicate.svg'),
                tooltipText: this.$localize.get('duplicate'),
                onClick: (item) => {
                  this.$store.dispatch('DUPLICATE_VACANCY', item)
                }
              },
              delete: {
                actionClass: 'delete',
                iconClass: 'delete-icon',
                icon: require('../assets/icon-delete-red.svg'),
                tooltipText: this.$localize.get('delete'),
                onClick: (item) => {
                  this.$store.dispatch('DELETE_TABLE_ITEM', item)
                }
              },
            }
          }
        }
        data.push(dataObject)
      })
      return data
    },
    profileSpecialistsTableHeaderData () {
      return [
        {
          prop: 'id',
          title: '№',
        },
        {
          prop: 'role',
          title: this.$localize.get('role'),
        },
        {
          prop: 'date',
          title: this.$localize.get('date'),
        },
        {
          prop: 'customer',
          title: this.$localize.get('customer'),
        },
        {
          prop: 'status',
          title: this.$localize.get('status'),
        },
        {
          prop: 'actions',
          title: this.$localize.get('actions'),
        },
      ]
    },
  },
  methods: {
    fetchTablesSpecialistsFilterData () {
      this.tables.vacancies.filterData = [
        {
          prop: 'customer',
          title: this.$localize.get('customer'),
          multiple: true,
          type: true,
          items: this.adminVacanciesTableDataCustomers,
          selectedItems: this.adminVacanciesTableDataCustomers,
        },
        {
          prop: 'status',
          title: this.$localize.get('status'),
          multiple: true,
          type: true,
          items: this.adminVacanciesTableDataStatuses,
          selectedItems: this.adminVacanciesTableDataStatuses,
        },
        {
          title: this.$localize.get('sort_by'),
          prop: 'sortType',
          multiple: false,
          items: this.profileSpecialistsTableHeaderData,
          selectedItems: this.profileSpecialistsTableHeaderData[0],
          sortDirection: 'asc'
        },
      ]
    },
    setFilterData (params) {
      this.tables.vacancies.filterData = params
    }
  },
}
</script>

<style lang="scss">
.admin-view {
  height: 100%;
}
</style>